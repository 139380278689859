<template>
    <div>
        <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">

            <div style="display: flex;justify-content: space-between;">
                <div>
                    <h3><b>{{ $t('invoice_purchase.purchase_order')}}</b></h3>
                </div>
                <div>
                    <p style="margin-bottom: 0.5rem;">{{data.inventory? data.inventory.name : ''}}</p>
                    <p style="margin-bottom: 0.5rem;">{{(data.inventory && data.inventory.city)? data.inventory.city.name+',' : ''}} {{data.inventory? data.inventory.address_1 : ''}}</p>
                    <p style="margin-bottom: 0.5rem;">{{data.inventory? data.inventory.mobile : ''}}</p>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 50px;">
                <div>
                    <p style="margin-bottom: 0.5rem;"><b>{{ $t('invoice_purchase.supplier')}}</b></p>
                    <p style="margin-bottom: 0.5rem;">{{ data.supplier ? data.supplier.name:'' }}</p>
                    <p style="margin-bottom: 0.5rem;">{{ data.supplier ? data.supplier.mobile :'' }}</p>
                    <p style="margin-bottom: 0.5rem;">{{ data.supplier ? data.supplier.email : ''}}</p>
                </div>
                <div>
                    <p style="margin-bottom: 0.5rem;"><b>{{ $t('invoice_purchase.due_date')}}</b> <span>{{data.purchase_date}}</span></p>
                </div>
            </div>

            <table :style="{'text-align': (lang == 'ar'? 'right': 'left') ,'margin-top': '50px'}" cellpadding="0" cellspacing="0">
                <thead>
                <tr style="background:#CAD3C8">
                    <td class="border color">{{$t('invoice_purchase.item')}}</td>
                    <td class="border color">{{$t('invoice_purchase.description') }}</td>
                    <td class="border color">{{$t('invoice_purchase.unit_price') }}</td>
                    <td class="border color">{{$t('invoice_purchase.quantity') }}</td>
                    <td class="border color">{{$t('invoice_purchase.subtotal') }}</td>
                </tr>

                </thead>
                <tbody>
                <tr class="item" v-for="(row, index) in items_list" :key="index">
                    <td class="border">{{row.item.name}}</td>
                    <td class="border">{{row.description}}</td>
                    <td class="border">{{row.unit_price}}</td>
                    <td class="border">{{row.qty}}</td>
                    <td class="border">{{row.subtotal}}</td>
                </tr>

                </tbody>
                <tfoot>
                <tr>
                    <th colspan="3"></th>
                    <th>{{$t('invoice_purchase.total')}}</th>
                    <td>{{data.local_total_order}} {{data.currency_name}}</td>
                </tr>
<!--                <tr>-->
<!--                    <th colspan="3"></th>-->
<!--                    <th>{{$t('invoice_purchase.value_added')}} (%{{total_taxes_sum}})</th>-->
<!--                    <td>{{total_taxes}} {{data.currency_name}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th colspan="3"></th>-->
<!--                    <th>{{$t('invoice_purchase.shipping')}}</th>-->
<!--                    <td>{{data.shipping_fees}} {{data.currency_name}}</td>-->
<!--                </tr>-->

<!--                <tr style="background:#CAD3C8">-->
<!--                    <th colspan="3"></th>-->
<!--                    <th>{{$t('invoice_purchase.total')}}</th>-->
<!--                    <td>{{total}} {{data.currency_name}}</td>-->
<!--                </tr>-->
                </tfoot>


            </table>
            <br>
            <div :style="{'text-align': (lang == 'ar'? 'left': 'right')}">
                <vue-barcode v-if="data.purchase_code" :value="data.purchase_code" :options="{ displayValue: true }"></vue-barcode>
            </div>

        </div>
    </div>
</template>

<script>
    import {jsPDF} from "jspdf";
    import html2canvas from "html2canvas";
    import ApiService from "@/core/services/api.service";

    import VueBarcode from '@chenfengyuan/vue-barcode';
    import jsPdfExport from "@/core/config/jsPdfExport";

    export default {
        name: "purchases-orders-invoice",
        components: {VueBarcode},

        data() {
            return {
                lang: this.$i18n.locale,
                mainRoute: 'purchases/purchase-orders',

                data: {},
                items_list: [],
                idEditing: this.$route.params.purchase_invoice_id ? this.$route.params.purchase_invoice_id : null,
              type: this.$route.params.type ? this.$route.params.type : null,
                items_total: 0,
                total_taxes: 0,
                total_taxes_sum: 0,
                total: 0,
                show: false,
            }
        },
        watch: {
            // items_list: {
            //     handler(val) {
            //         if (val.length > 0) {
            //             this.items_total = this.$_.sumBy(this.items_list, 'subtotal');
            //             this.items_total = this.items_total ? this.items_total : 0;
            //             this.total_taxes_sum = this.$_.sumBy(this.items_list, (row) => {
            //                 return row.tax_1 ? row.tax_1.value_rate : 0;
            //             });
            //
            //             this.total_taxes = (this.items_total * (this.total_taxes_sum / 100));
            //             this.total = this.items_total + this.total_taxes + this.data.shipping_fees;
            //
            //         }
            //     },
            //     deep: true
            // }
        },
        methods: {
            printCustomerInvoice() {
                // let element = document.getElementById('invoice');
                // let pdf = jsPDF("p", "pt", "a4");
                // // let check_lang = this.lang == 'ar' ? 'arabic' : 'english';
                // html2canvas(element).then(canvas => {
                //     let image = canvas.toDataURL('image/png', 1.0);
                //     pdf.addImage(image, 'PNG', 60, 15);
                //     // pdf.save(`${'purchase invoice '}${check_lang}${'.pdf'}`);
                //     pdf.autoPrint();
                //     let _url = pdf.output("bloburl");
                //     location.href = _url;
                // });
              let _id = 'invoice';
              this.exportPDF(_id);
            },
          exportPDF(_id) {
            let that = this;
            // let pdf = jsPDF("p", "px", 'a4');
            //
            // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
            // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
            //
            // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
            let pdf = jsPdfExport("p", "px", 'a4');
            pdf.html(document.getElementById(_id), {
              html2canvas: {
                scale: 0.5,
              },
              callback: function (pdf) {
                if (that.type == 'pdf') {
                  pdf.save('invoice');
                } else {
                  pdf.autoPrint();
                  pdf.output('dataurlnewwindow');
                }

                // var iframe = document.createElement('iframe');
                // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
                // document.body.appendChild(iframe);
                // iframe.src = pdf.output('datauristring');
              }
            });
          },
            async getData() {
                let _id = this.idEditing;
                await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

                    this.data = response.data.data;
                    this.items_list = response.data.data.items_list;
                    this.show = true;
                });
            },
        },
        mounted() {
            let promise = this.getData();
            Promise.all([promise]).then(() => {
                this.printCustomerInvoice();
            });
            // window.print();
        }
    }
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
    canvas {
        height: 100;
    }

    table, thead, tr, th, tbody, td {
        border: 1px solid #000 !important;
    }

    .invoice-box {
        background-color: #fff;
        width: 600px;
        margin: auto;
        padding: 30px;
        border: 1px solid #fff;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #000;
    }

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }

    .invoice-box table td {
        /* padding: 5px; */
        vertical-align: top;
    }

    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }

    .invoice-box table tr td:nth-child(3) {
        text-align: right;
    }

    .invoice-box table tr.top td.title {
        font-size: 45px;
        line-height: 45px;
        color: #000;
    }

    .padd {
        padding-bottom: 100px;

    }

    .invoice-box table tr.information td {
        padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
        border: 1px solid #000 !important;
        background: #CAD3C8;
        border-bottom: 1px solid #fff;
        font-weight: bold;
    }

    .border {
        border: 1px solid #000 !important;
        padding: 5px;
    }

    .color {
        background-color: #CAD3C8;
    }

    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
    }

    .invoice-box table tr.item.last td {
        border-bottom: none;
    }

    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }

        .invoice-box table tr.information td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }

    /** RTL **/

    .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }

    .invoice-box.rtl table {
        text-align: right;
    }

    .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
    }
</style>